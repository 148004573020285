body {
    background-color: #d4dae3 !important;
}

.paraDiv {
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.6;
    font-weight: 500;
    text-indent: 3rem;
}

.paraDiv ul {
    text-indent: 5rem;
}

@media (max-width: 720px) {
    .paraDiv ul {
        text-indent: 2rem;
    }
}

.product-image {
    margin-top: 15px;
    max-height: 500px;
    max-width: 90%;
}
