body {
    margin: 0;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(181, 206, 221);
    background: linear-gradient(
        90deg,
        rgba(181, 206, 221, 1) 0%,
        rgba(212, 218, 227, 1) 25%,
        rgba(212, 218, 227, 1) 75%,
        rgba(181, 206, 221, 1) 100%
    );
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#about-us,
#contact-us,
#services,
#products,
#blog,
#media-centre {
    padding-top: 20px;
}

#footer{
    background-color: rgba(0,0,0,0.06);
    margin-top:30px;
}

.title {
    margin-bottom: 15px !important;
}


.MuiDivider-root {
    height: 2px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.tab-header {
    color: black !important;
    font-weight: bold !important;
    border-bottom: none !important;
}

.tab-header[disabled] {
    border-bottom: 4px solid black !important;
}

.padding-top-20{
    padding-top: 20px;
}