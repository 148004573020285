.navbar-tall-transparent {
    background-color: rgba(247, 247, 247, 0.8) !important;
    height: 100px;
}

#navbar {
    justify-content: center;
    transition: all 0.5s ease-in;
    background-color: rgba(247, 247, 247, 1);
}

.image-tall {
    height: 100px;
    width: 180px;
}
.image-short {
    height: 60px;
    width: 120px;
}
@media(max-width:500px){
    .image-tall {
        height: 60px;
        width: 100px;
    }
    .image-short {
        height: 40px;
        width: 80px;
    }
}


.logo {
    cursor: pointer;
}